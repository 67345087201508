.popup-content {
  /*margin: auto;*/
  background: rgb(255, 255, 255);
  /*width: 50%;*/
  /*padding: 5px;*/
  /*text-align: center;*/
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.signatureCanvas {
  border: 1px solid black;
  width: auto;
  height: 75vh;
  display: block;
}
