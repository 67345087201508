.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

.popup-content {
  margin: auto;
  /*background: rgb(255, 255, 255);*/
  background-color: #333B52;
  width: 50%;
  padding: 35px 50px;
  border-radius: 10px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.icon-link:hover {
  cursor: pointer;
}
