@charset "utf-8";

@import "../../node_modules/bulma/sass/utilities/initial-variables";
@import "../../node_modules/bulma/sass/utilities/functions";

// Set custom colors for dark mode

$background: white;
$foreground-1: #f6f6f6;
$foreground-2: #a0a0a1;
$table-row-border: #727272;
$grey: #95999e;
$cobalt: #536287;

// Override Bulma's colors
$danger: #F14668;
$primary: #00D1B2;
$body-background-color: $background;
//$title-color: white;
$subtitle-color: $primary;
$subtitle-weight: bold;

$table-row-hover-background-color: $grey-lighter;
$table-striped-row-even-hover-background-color: $grey-lighter;



@import "../../node_modules/bulma/sass/utilities/_all";
@import "../../node_modules/bulma/sass/base/_all";
@import "../../node_modules/bulma/sass/elements/_all";
@import "../../node_modules/bulma/sass/form/_all";
@import "../../node_modules/bulma/sass/components/_all";
@import "../../node_modules/bulma/sass/grid/_all";
@import "../../node_modules/bulma/sass/helpers/_all";
@import "../../node_modules/bulma/sass/layout/_all";


a{color: $primary;}
a:hover{color:$primary-dark;}
.side-nav-sub-link{
  color:$grey;
}
.side-nav-sub-link:hover{
  color:$primary;
}
.side-nav{
  background-color:$foreground-1;
  min-height : 100vh;
}
tr{background-color:$foreground-1;}

th{
  color: $title-color;
  background-color: $foreground-1;
}

td{
  color: $grey;
  vertical-align: middle !important;
}


form, .form{
  width: 100%;
}

p, .label {
  color:$grey;
}

.input, select, select:valid, textarea, textarea:valid, .textarea, .input[type=checkbox]{
  background:$background;
  color:$grey;
}
.input::placeholder, select::placeholder, textarea::placeholder, .textarea::placeholder, select, option, select:valid {
  color:$grey;
}

select:focus, textarea:focus, input[type=text]:valid, input[type=password]:valid, input[type=email]:valid, .input:valid {
  background:$background !important;
}
#email-input:valid {
  background-color: $background !important;
}
.page{
  background: $foreground-1;
  padding:5%;
}

.feedback-hidden {
  visibility: hidden;
  min-height: 1.1rem;
}

.suggestion:hover {
  background-color: $grey-lighter;
}

.disabledCursor, .disabledCursor:hover {
  cursor: default;
  color:$grey;
}
.not-editing:after{
  border-color: $background !important;
}

.is-cobalt{
  color: #95999e;
}

.sublabel{
  color: #95999e;
  font-weight: bold;
}